import React, { useContext } from 'react'
import { graphql, Link } from 'gatsby'
import classNames from 'classnames'
import AccordionContext from 'react-bootstrap/AccordionContext'
import Accordion from 'react-bootstrap/Accordion'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import Layout from '../components/Layout/Layout'

const ns = `faq-page`

const FAQPage = ({ data }) => {
  const { markdownRemark } = data
  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext)

    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    )

    const isCurrentEventKey = currentEventKey === eventKey

    return (
      <button
        type="button"
        className={[
          'btn btn-link ',
          isCurrentEventKey ? ' opened ' : ' closed ',
        ]}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    )
  }

  return (
    <Layout>
      <div className={rootClassnames}>
        <div className={`${ns}__container`}>
          <h1 className={`${ns}__title`}>{markdownRemark.frontmatter.title}</h1>
          <div className={`${ns}__content-container`}>
            <div className={`${ns}__sections`}>
              {markdownRemark.frontmatter.faqs.map((faq, index) => (
                <Accordion key={index}>
                  <ContextAwareToggle eventKey="0">
                    <div className="accordian-title">
                      <p>{faq.question}</p>
                      <span className="text-right">
                        <span className="opened-icon">+</span>
                        <span className="closed-icon">—</span>
                      </span>
                    </div>
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey="0">
                    <div className="accordian-content">
                      <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                        {faq.answer}
                      </ReactMarkdown>
                    </div>
                  </Accordion.Collapse>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default FAQPage

FAQPage.defaultProps = {
  content: {
    faqs: [
      {
        question: 'What is the story of The Underground Museum?',
        answer: '/',
      },
      {
        question: 'Dan Flavin',
        answer: '/',
      },
    ],
  },
}

export const faqPageQuery = graphql`
  query FaqPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        faqs {
          question
          answer
        }
      }
    }
  }
`
